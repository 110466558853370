import { useUserStore } from '@st/user/stores/useUserStore'

export type RegularBonusType = 'cashback' | 'rakeBack' | 'reload'

export interface ReadyToClaimBonus {
  status: 'readyToClaim'
  id: string
  type: RegularBonusType
  currencyId: number
  amount: string
  bonusId: number
  expiredAt: string
  level?: number
  accrualAt?: never
}

export interface WaitingForAccrualBonus {
  status: 'waitingForAccrual'
  id: string
  type: RegularBonusType
  accrualAt: string
  amount?: string
  currencyId?: number
  level?: number
  expiredAt?: never
  bonusId?: never
}

export interface DefaultBonus {
  status: 'default'
  id: string
  type: RegularBonusType
  amount?: string
  currencyId?: number

  expiredAt?: never
  accrualAt?: never
  bonusId?: never
  level?: never
}

export type RegularBonus =
  | ReadyToClaimBonus
  | WaitingForAccrualBonus
  | DefaultBonus

/**
 * Логика работы с регулярными бонусами довольно поехавшая
 * Используется огромная пачка роутов чтобы все правильно отрисовать
 *
 * Этот стор нужен чтобы все это говнище инкапсулировать
 * На выходе получается единообразный массив бонусов типа RegularBonus
 *
 * Жизненный цикл бонуса может включать 3 этапа
 * status: default - юзер не участвует в бонусной программе
 * status: waitingForAccrual - юзер участвует в программе и ждет начисления награды
 * status: readyToClaim - награда начислена и ее можно забрать
 */
export const useRegularBonusesStore = defineStore('regularBonuses', () => {
  const {
    data,
    execute: fetchBonuses,
    refresh,
  } = useStFetch('/bonus/find', {
    method: 'post',
    body: {
      params: {
        expired: false,
        claimed: false,
        bonusProgramType: ['cashback', 'rakeBack', 'reload'],
      },
      pagination: {
        perPage: 50,
        orderBy: [
          {
            fieldName: 'id',
            sortOrder: 'ASC',
          },
        ],
      },
    },
    immediate: false,
  })
  const regularBonusesCount = ref(0)
  const bonuses = computed(() => data.value?.data ?? [])
  const {
    data: cashbackPrograms,
    execute: fetchCashbackList,
    refresh: refreshCashbackList,
    pending: isCashbackListLoading,
  } = useStFetch('/cashback/list', {
    method: 'post',
    immediate: false,
    watch: false,
    default: () => [],
  })

  const {
    data: reloadPrograms,
    execute: fetchReloadPrograms,
    refresh: refreshReloadPrograms,
  } = useStFetch('/reload-program/list', {
    method: 'post',
    immediate: false,
    watch: false,
    default: () => [],
  })

  const cashbacks = computed<RegularBonus[]>(() => {
    if (!cashbackPrograms.value.length) {
      return [
        {
          type: 'cashback',
          status: 'default',
          id: 'cashback',
        },
      ]
    }

    return cashbackPrograms.value.map((program) => {
      const bonus = bonuses.value.find(
        (item) =>
          item.bonusProgramId === program.bonusProgramId &&
          item.bonusProgramType === 'cashback',
      )

      if (bonus) {
        return {
          type: 'cashback',
          status: 'readyToClaim',
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          expiredAt: bonus.expiredAt,
          id: `cashback${bonus.id}`,
        }
      }
      return {
        type: 'cashback',
        status: 'waitingForAccrual',
        accrualAt: program.accrualAt,
        id: 'cashback',
      }
    })
  })

  const reloads = computed<RegularBonus[]>(() => {
    const reloadsWithPrograms = reloadPrograms.value.map((program) => {
      const bonus = bonuses.value.find(
        (item) =>
          item.bonusProgramId === program.bonusProgramId &&
          item.bonusProgramType === 'reload',
      )

      if (bonus) {
        return {
          id: `reload${program.bonusProgramId}`,
          type: 'reload' as const,
          status: 'readyToClaim' as const,
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          expiredAt: bonus.expiredAt,
          level: bonus.bonusForDepositProgram?.level,
        }
      }
      return {
        id: `reload${program.bonusProgramId}`,
        type: 'reload' as const,
        status: 'waitingForAccrual' as const,
        accrualAt: program.accrualAt,
      }
    })
    const reloadsWithoutPrograms = bonuses.value
      .filter(
        (item) =>
          item.bonusProgramType === 'reload' &&
          !reloadsWithPrograms.find(({ bonusId }) => item.id === bonusId),
      )
      .map((bonus) => ({
        id: `reload${bonus.id}`,
        type: 'reload' as const,
        status: 'readyToClaim' as const,
        amount: bonus.amount,
        currencyId: bonus.currencyId,
        bonusId: bonus.id,
        expiredAt: bonus.expiredAt,
        level: bonus.bonusForDepositProgram?.level,
      }))

    return [...reloadsWithPrograms, ...reloadsWithoutPrograms]
  })

  const rakebacks = computed<RegularBonus[]>(() => {
    const rakebackBonuses = bonuses.value.filter(
      (bonus) => bonus.bonusProgramType === 'rakeBack',
    )

    const result: RegularBonus[] = rakebackBonuses.map((bonus) => ({
      id: `rakeBack${bonus.id}`,
      type: 'rakeBack',
      status: 'readyToClaim',
      bonusId: bonus.id,
      amount: bonus.amount,
      currencyId: bonus.currencyId,
      expiredAt: bonus.expiredAt,
    }))

    if (!result.length) {
      result.push({
        id: 'rakeBack',
        type: 'rakeBack',
        status: 'default',
        amount: '0',
        currencyId: 1,
      })
    }

    return result
  })

  const completedBonuses = computed<RegularBonus[]>(() => [
    ...cashbacks.value,
    ...reloads.value,
    ...rakebacks.value,
  ])

  const { isAuthenticated } = storeToRefs(useUserStore())

  const io = useSocket()
  io.on('welcomeProgram', async () => {
    await fetchBonuses()
    regularBonusesCount.value = data.value?.paging.total ?? 0
    fetchCashbackList()
    fetchReloadPrograms()
  })

  async function refreshBonuses() {
    if (isAuthenticated.value) {
      await refresh()
      regularBonusesCount.value = data.value?.paging.total ?? 0
      refreshCashbackList()
      refreshReloadPrograms()
    } else {
      regularBonusesCount.value = 0
    }
  }
  watch(
    () => isAuthenticated.value,
    async (isAuth) => {
      if (isAuth) {
        await fetchBonuses()
        regularBonusesCount.value = data.value?.paging.total ?? 0
        fetchCashbackList()
        fetchReloadPrograms()
      } else {
        regularBonusesCount.value = 0
      }
    },
    { immediate: true },
  )

  return {
    completedBonuses,
    reloadPrograms,
    isCashbackListLoading,
    regularBonusesCount,
    refreshBonuses,
  }
})
